import cookieCheck from '3rd-cookie-check'
import auth0 from 'auth0-js'

import horizons_postregister_lightbox from '../core/horizons_lightbox/horizons_postregister_lightbox.js'
import { readCookie, setCookie } from '../utils/cookie_manager'
import on from '../utils/events_delegation'

// Variables to be sent to the Auth0 login view
const extraMetrics = [
  'campaign_content',
  'campaign_cp_origin',
  'campaign_cp',
  'campaign_medium',
  'campaign_name',
  'campaign_source',
  'campaign_term',
  'cookies_consent', //
  'device_type',
  'hotel_headquarter_final',
  'hotel_headquarter_origin',
  'page_language',
  'page_platform',
  'promo_is_miiberostar',
  'promo_is_resident',
  'user_id_horizons', //
  'user_id',
  'user_level',
  'visitor_country_code',
  'visitor_country',
  'visitor_depth', //
  'visitor_has_reservation',
  'visitor_headquarter_market',
  'visitor_id', //
  'visitor_internal_market',
  'visitor_is_buyer',
  'visitor_is_logged', //
  'visitor_is_repeater',
  'visitor_personalized_tratement',
  'visitor_recive_advertising',
  'visitor_recurrence',
  'visitor_session_count',
  'visitor_session_id',
  'visitor_type',
]

function extractProperties(obj, keys) {
  const newObject = {}
  keys.forEach(function (key) {
    if (obj.hasOwnProperty(key)) {
      newObject[key] = obj[key]
    }
  })
  return newObject
}

function getCurrentLocale() {
  let locale = readCookie('locale')
  if (!locale) {
    const languajeSelectorLinks = document.querySelector('ul.js-language-selector-links li.active a')
    locale = languajeSelectorLinks !== null ? languajeSelectorLinks.getAttribute('data-locale') : null
  }
  return locale
}

export function auth0Login(register = false) {
  setCookie('prelogin_url', window.location.href)

  const webAuth = new auth0.WebAuth({
    domain: AUTH0_CONFIG.custom_domain,
    clientID: AUTH0_CONFIG.clientId,
    redirectUri: AUTH0_CONFIG.redirectUri,
    audience: AUTH0_CONFIG.audience,
    responseType: 'token id_token',
    scope: 'openid profile user_metadata',
  })

  let environment = document.querySelector('#environment')?.value
  if (document.querySelector('#environment')?.value === 'development') {
    environment = AUTH0_CONFIG.connection === 'STAGING' ? 'uat' : AUTH0_CONFIG.connection.toLowerCase()
  }

  const microsite = document.querySelector('#microsite')?.value
  const backUrl = window.location.href
    .replaceAll(/([\?&])open=[^&$]+&?/g, '$1') // We replace the open=login parameters to avoid redirection loops
    .replaceAll(/[\?&]$/g, '') // And then replace & at the end
  const locale = getCurrentLocale()

  webAuth.authorize({
    ui_locales: locale,
    application: 'CMS',
    back: backUrl,
    register: register ? '1' : '0',
    environment,
    microsite,
    update_metadata: `${document.location.origin}/update_app_metadata`,
    responseType: 'code',
    extraMetric: extractProperties(window.utag_data, extraMetrics),
  })
}

export function auth0UpdateMetadata(locale, microsite, environment) {
  const csrfToken = document.querySelector('[name=csrf-token]').content
  fetch('/update_app_metadata', {
    method: 'POST',
    body: JSON.stringify({ locale, microsite, environment }),
    headers: { 'Content-type': 'application/json; charset=UTF-8', 'X-CSRF-Token': csrfToken },
  })
    .then(r => r.json())
    .catch(error => {
      console.error(`Update metadata error: ${error}`)
    })
}

function checkConfig() {
  return (
    AUTH0_CONFIG.domain.length > 1 &&
    AUTH0_CONFIG.clientId.length > 1 &&
    AUTH0_CONFIG.redirectUri.length > 1 &&
    AUTH0_CONFIG.audience.length > 1
  )
}

function thirdPartyCookieCheck() {
  const loggedMessagesCookie = readCookie('logged_messages')
  const currentUser = loggedMessagesCookie?.includes('true') ? true : false
  const confirmUser = readCookie('auth0_login') !== 'true'
  const microsite = document.querySelector('#microsite')?.value || 'iberostar'

  const clientAuth0 = new auth0.WebAuth({
    domain: AUTH0_CONFIG.domain,
    clientID: AUTH0_CONFIG.clientId,
    redirectUri: AUTH0_CONFIG.redirectUri,
    audience: AUTH0_CONFIG.audience,
    connection: AUTH0_CONFIG.connection,
    responseType: 'token id_token',
    scope: 'openid profile user_metadata',
  })

  cookieCheck({
    timeout: 3000,
    iframeSrc: 'https://viglesias.github.io/3rdCookieChecker/index.html',
  }).then(function (resultCookieCheck) {
    // Verificamos si viene de APP para no aplicar el checkSession
    const is_app = readCookie('in_app') === 'true'
    const is_simulated = readCookie('simulated_account') === 'true'
    if (currentUser && !is_app && !is_simulated && resultCookieCheck.supported) {
      clientAuth0.checkSession(
        {
          audience: AUTH0_CONFIG.audience,
          scope: 'read:current_user',
        },
        function (err) {
          if (err) {
            console.error(`Error checkSession auth0: ${err.description}`)
            // Forzamos el cierre de sesión (este caso pretende forzar el cierre por ejemplo si el usuario ha cerrado sesión en CRS de fomra que vayan acompasados)
            const logout_url = `${window.location.protocol}//${window.location.host}/auth/logout`
            //setCookie('logged_messages', false, '-1')
            IB.cookieManager.deleteCookies(['logged_messages'])
            window.location.href = logout_url
          }
        }
      )
    } else if ((!currentUser || !confirmUser) && microsite != 'star_agents' && checkConfig()) {
      // Si no hay sesión de rails
      clientAuth0.checkSession({}, function (err, authResult) {
        if (err) {
          console.error(`Error checkSession auth0 (init_session): ${err.description}`)
          //setCookie('logged_messages', false, '-1')
          IB.cookieManager.deleteCookies(['logged_messages'])
        } else if (authResult) {
          const csrfToken = document.querySelector('[name=csrf-token]').content
          // Si no tiene currentUser, no va a mostrar el formulario de postregisto document.cookie['user_metadata'] y obtiene valores de auth0 authResult
          fetch('/sso_or_postregister', {
            method: 'POST',
            body: JSON.stringify(authResult),
            headers: { 'Content-type': 'application/json; charset=UTF-8', 'X-CSRF-Token': csrfToken },
          })
            .then(r => r.json())
            .then(data => {
              if (data.postregister_form === true) {
                if (document.getElementById('session-tab-registration') === null) {
                  horizons_postregister_lightbox()
                }
              } else if (data.redirect_url !== undefined && data.redirect_url !== null) {
                location.href = data.redirect_url
              } else if (data.reload === true) {
                location.reload()
              }
            })
            .catch(error => {
              console.error(`SSO error: ${error}`)
            })
        }
      })
    }
  })
}

function initSSO() {
  thirdPartyCookieCheck()
  // Login - Añadimos la función on incluso en elementos que aún no se han cargado en la carga inicial.
  on({
    eventName: 'click',
    selector: `.js-login-link`,
    handler(e) {
      e.preventDefault()
      auth0Login(false)
    },
  })

  document.querySelectorAll('.js-register-link').forEach(link => {
    link.addEventListener('click', function (e) {
      e.preventDefault()
      auth0Login(true)
    })
  })

  // Login tras expiración del token
  if (document.querySelector('#auth0_expired_token')?.value === 'true') {
    auth0Login(false)
  }

  document.querySelector('#change_password')?.addEventListener('click', function (e) {
    e.preventDefault()
    const webAuth = new auth0.WebAuth({
      domain: AUTH0_CONFIG.custom_domain,
      clientID: AUTH0_CONFIG.clientId,
      redirectUri: AUTH0_CONFIG.redirectUri,
      audience: AUTH0_CONFIG.audience,
      responseType: 'token id_token',
      scope: 'openid profile user_metadata',
    })

    const locale = getCurrentLocale()
    const microsite = document.querySelector('#microsite')?.value || 'iberostar'
    const environment = document.querySelector('#environment')?.value

    auth0UpdateMetadata(locale, microsite, environment)

    let email = document.querySelector('.current-email').innerText

    if (
      document.documentElement.dataset.inMicrosite === 'star_agents' &&
      document.querySelector('.edit-user-content #agent_current_code')
    ) {
      email = document.querySelector('.edit-user-content #agent_current_code').value
    }
    // STA - StarAgents
    if (!email) email = document.querySelector('.current-email').value

    webAuth.changePassword(
      {
        ui_locales: locale,
        application: 'CMS',
        connection: AUTH0_CONFIG.connection,
        environment,
        microsite,
        email,
      },
      function (err) {
        var text = err ? '#change-password-error' : '#change-password-success'

        Modal.showHtml({
          html: document.querySelector(text).outerHTML,
          modalClass: 'multi-modal-full-container',
          onShow: () => {
            ScrollLock.on()
          },
          onClose: () => {
            ScrollLock.off()
          },
          awaitCloseAnimation: true,
        })
      }
    )
  })

  // Parameter added to utag_data
  window.utag_data['cookies_consent'] = readCookie('cookies_consent') ? readCookie('cookies_consent') : ''

  // Here we check for the open parameter and redirect to auth0 login page
  const TYPE_LIGHTBOX = IB.urlParser.getParam('open')
  const isLogged = IB?.browsing_sessions?.isUserLogged()

  if (!isLogged) {
    // Capture microsite value to avoid if registration goes to SSO view
    const microsite = document.querySelector('#microsite')?.value

    if (microsite === 'star_agents') return

    if (TYPE_LIGHTBOX === 'login') {
      setCookie('prelogin_url', window.location.href)
      auth0Login(false)
    } else if (TYPE_LIGHTBOX === 'register') {
      if (microsite !== 'star_agents') {
        setCookie('prelogin_url', window.location.href)
        auth0Login(true)
      }
    }
  }
}

initSSO()
